<template>
  <div id="page" class="page" ref="page">
    <!-- 顶部tab栏 -->
    <div class="tabBox">
      <div
        :class="index == tabItemIndex ? 'activeTabItem' : 'tabItem'"
        v-for="(item, index) in tabItemList"
        :key="index"
        @click="selectTabItem(item, index)"
      >
        {{ item }}
        <div class="thisTabItemLine" v-show="index == tabItemIndex"></div>
      </div>
    </div>
    <!-- 基本信息 -->
    <div class="essentialInfo">
      <!-- banner -->
      <div class="bannerBox">
        <img :src="detail.picture" alt="" />
      </div>
      <!-- 社群名称 -->
      <div class="associationName">{{ detail.associationName }}</div>
      <div class="mainTitle">
        <div class="mainTitle-left">
          <div class="title">基本信息</div>
          <div class="titleLine"></div>
        </div>
      </div>
      <div class="detailInfo">
        <div class="detailitem" v-if="detail.directorName">
          <div class="label">社群负责人：</div>
          <div class="content">{{ detail.directorName }}</div>
        </div>
        <div class="detailitem" v-if="detail.memberNum">
          <div class="label">社群参与人数：</div>
          <div class="content">{{ detail.memberNum }}</div>
        </div>
        <div class="detailitem" v-if="detail.organiser">
          <div class="label">社群组织方：</div>
          <div class="content">{{ detail.organiser }}</div>
        </div>
        <div class="detailitem" v-if="detail.establishTime">
          <div class="label">社群成立时间：</div>
          <div class="content">{{ detail.establishTime }}</div>
        </div>
      </div>
      <div class="mainTitle">
        <div class="mainTitle-left">
          <div class="title">社群成员</div>
          <div class="titleLine"></div>
        </div>
      </div>
      <div class="memberBox" @click="goCommunityMembers()">
        <div class="member" v-if="memberList[0]">
          <img
            :src="
              memberList[0].avatar == ''
                ? require('./img/defult_avatar.png')
                : memberList[0].avatar
            "
          />
        </div>
        <div class="ml-16 member" v-if="memberList[1]">
          <img
            :src="
              memberList[1].avatar == ''
                ? require('./img/defult_avatar.png')
                : memberList[1].avatar
            "
          />
        </div>
        <div class="ml-16 member" v-if="memberList[2]">
          <img
            :src="
              memberList[2].avatar == ''
                ? require('./img/defult_avatar.png')
                : memberList[2].avatar
            "
          />
        </div>
        <div class="ml-16 member" v-if="memberList[3]">
          <img
            :src="
              memberList[3].avatar == ''
                ? require('./img/defult_avatar.png')
                : memberList[3].avatar
            "
          />
        </div>
        <div class="ml-16 member" v-if="memberList[4]">
          <img
            :src="
              memberList[4].avatar == ''
                ? require('./img/defult_avatar.png')
                : memberList[4].avatar
            "
          />
        </div>
        <div class="memberSum">
          <div class="memberNum">共{{ this.memberList.length }}人</div>
          <img src="./img/arrow.png" alt="" />
        </div>
      </div>
      <div class="mainTitle">
        <div class="mainTitle-left">
          <div class="title">社群介绍</div>
          <div class="titleLine"></div>
        </div>
      </div>
      <div class="associationContent">
        {{ detail.description }}
      </div>
    </div>
    <!-- 最新活动 -->
    <div class="aatestActivities" v-if="detail.isJoin == 1">
      <div class="mainTitle">
        <div class="mainTitle-left">
          <div class="title">最新活动</div>
          <div class="titleLine"></div>
        </div>
        <div class="mainTitle-right" @click="goCommunityActivityList()">
          <div class="more">更多</div>
          <img src="./img/arrow.png" alt="" />
        </div>
      </div>
      <!-- 活动列表 -->
      <div class="activitiesList">
        <div
          class="activitieItem"
          v-for="(item, index) in activityList"
          :key="index"
          @click="goCommunityActivityDetail(item)"
        >
          <div class="activitieItem-top">
            <img src="./img/activity_icon.png" />
            <div class="activitieName">{{ item.activityName }}</div>
            <div class="date">{{ item.intime }}</div>
          </div>
          <div class="activitieItem-bottom">
            <div class="info-top">
              <img :src="item.activityPicture" />
              <div class="activitie-info">活动介绍：{{ item.content }}</div>
            </div>
            <div class="info-bottom">
              <p>
                活动时间：<span>{{ item.startDate }}</span
                >-<span>{{ item.endDate }}</span>
              </p>
              <p>
                活动人数：<span style="color: #fe9615">{{
                  item.applyUserCount
                }}</span
                >/<span>{{
                  item.limitCount == 0 ? "-" : item.limitCount
                }}</span>
              </p>
              <p>活动地点：{{ item.activityAddress }}</p>
            </div>
          </div>
          <div
            class="btn_box orange"
            v-if="item.isJoin == '2'"
            @click.stop="joinOrNot(1, item)"
          >
            参与
          </div>
          <div
            class="btn_box withe"
            v-else-if="item.isJoin == '1'"
            @click.stop="joinOrNot(2, item)"
          >
            取消参与
          </div>
          <div class="btn_box empty" v-else-if="item.isJoin == '3'">已结束</div>
          <div class="btn_box empty" v-else-if="item.isJoin == '4'">已满员</div>
          <div class="btn_box empty" v-else-if="item.isJoin == '5'">进行中</div>
        </div>
      </div>
    </div>
    <!-- 社群动态 -->
    <div class="communityDynamics" v-if="detail.isJoin == 1">
      <div class="mainTitle">
        <div class="mainTitle-left">
          <div class="title">社群动态</div>
          <div class="titleLine"></div>
        </div>
        <div class="mainTitle-right" @click="goCommunitDynamic()">
          <div class="more">更多</div>
          <img src="./img/arrow.png" alt="" />
        </div>
      </div>
      <div class="dynamicsList">
        <div
          class="dynamicsItem"
          v-for="(item, index) in dynamicList"
          :key="index"
          @click="toDynamicDetail(item)"
        >
          <div class="dynamicsItem-top">
            <img
              :src="
                item.avatar == ''
                  ? require('./img/defult_avatar.png')
                  : item.avatar
              "
            />
            <div class="name">{{ item.nickName }}</div>
          </div>
          <div class="dynamicsItem-center">
            <v-open :showContent="item.topicContent"></v-open>
            <img
              v-if="item.topicPicture != ''"
              :src="item.topicPicture.split(',')[0]"
            />
          </div>
          <div class="dynamicsItem-bottom">
            <div class="time">{{ dateDiff(item.createTime, null) }}</div>
            <img src="./img/zan.png" class="img1" />
            <div class="num">{{ item.praiseCount }}</div>
            <img src="./img/message_icon.png" class="img2" />
            <div class="num">{{ item.commentCount }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 参加活动弹框 -->
    <div class="blackBg" @click="isDialog = false" v-if="isDialog == true">
      <div class="diglog">
        <div class="top_box">
          {{ dialogStr }}
        </div>
        <div class="bottom_box">
          <div class="left_box" @click="isDialog = false">取消</div>
          <div class="right_box" @click="confirm()">确认</div>
        </div>
      </div>
    </div>
    <!-- 按钮区域 -->
    <div class="underReview" v-if="detail.isJoin === 2">
      您的加入申请正在审核中...
    </div>
    <div
      class="joinCommunity"
      v-else-if="detail.isJoin === 0"
      @click="goApplyJoin"
    >
      加入社群
    </div>
    <div
      class="auditFailed"
      v-else-if="detail.isJoin === 3"
      @click="goApplyJoin"
    >
      审核失败，请重新提交
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  getAssDetailForMiniUrl,
  getAssUserByIdUrl,
  getAssActivityListUrl,
  joinOrQuitUrl,
  selectDynamicsUrl,
} from "./api.js";
import wx from "weixin-js-sdk";
import { gloabalCount, toRegister } from "@/utils/common";

export default {
  name: "associationDetail",
  data() {
    return {
      tabItemIndex: 0,
      scrollFlag: true,
      tabItemList: ["基本信息", "最新活动", "社群动态"],
      id: null,
      isJoin: null,
      detail: {},
      activityId: null, //活动id
      isDialog: false, //是否参加活动弹框
      dialogFlag: null, //弹框类型
      dialogStr: "", //弹框文本
      memberList: [], //成员列表
      activityList: [], //活动列表
      dynamicList: [], //动态列表
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  created() {
    this.id = this.$route.query.id;
  },
  async mounted() {
    gloabalCount(this.id, 7, 1);
    await this.getDetail();
    await this.getMemberList();
    await this.getActivityList();
    await this.getDynamicList();
    if (this.isJoin == 1) {
      window.addEventListener("scroll", this.handleScroll, true);
    }
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    toDynamicDetail(item) {
      this.$router.push({
        name: "topicTitle",
        query: {
          id: item.id,
        },
      });
    },
    //获取社群动态列表
    async getDynamicList() {
      let params = {
        curPage: 1,
        pageSize: 3,
        topicType: "17",
        associationId: this.id,
        tenantId: this.tenantId,
        userId: this.userId,
      };
      let res = await this.$axios.get(`${selectDynamicsUrl}`, {
        params: params,
      });
      if (res.code === 200) {
        this.dynamicList = res.data.records;
      }
    },
    // 获取社群活动
    async getActivityList() {
      let params = {
        curPage: 1,
        pageSize: 3,
        associationId: this.id,
        userId: this.userId,
      };
      let res = await this.$axios.get(`${getAssActivityListUrl}`, {
        params: params,
      });
      if (res.code === 200) {
        this.activityList = res.data.records;
      }
    },
    // 获取社群成员列表
    async getMemberList() {
      let params = {
        associationId: this.id,
        userId: this.userId,
      };
      let res = await this.$axios.get(`${getAssUserByIdUrl}`, {
        params: params,
      });
      if (res.code == 200) {
        this.memberList = res.data;
      }
    },
    // 跳转社群活动详情页面
    goCommunityActivityDetail(data) {
      //跳转到webvie活动详情页面
      this.$router.push({
        name: "communityActivityDetail",
        query: {
          id: data.id,
        },
      });
    },
    //参加或取消参加活动
    joinOrNot(flag, data) {
      this.activityId = data.id;
      this.dialogFlag = flag;
      if (this.dialogFlag == 1) {
        this.dialogStr = "是否确认参与该活动？";
      } else if (this.dialogFlag == 2) {
        this.dialogStr = "是否取消参与该活动？";
      }
      this.isDialog = true;
    },
    // 跳转社群活动列表页面
    goCommunityActivityList() {
      this.$router.push({
        name: "communityActivityList",
        query: {
          id: this.id,
        },
      });
    },
    // 跳转社群成员列表页面
    goCommunityMembers() {
      this.$router.push({
        name: "communityMembers",
        query: {
          id: this.id,
        },
      });
    },
    handleScroll(e) {
      let num0 =
        document.getElementsByClassName("essentialInfo")[0].offsetTop -
        document.getElementsByClassName("essentialInfo")[0].offsetTop;
      let num1 =
        document.getElementsByClassName("aatestActivities")[0].offsetTop -
        document.getElementsByClassName("essentialInfo")[0].offsetTop;
      let num2 =
        document.getElementsByClassName("communityDynamics")[0].offsetTop -
        document.getElementsByClassName("essentialInfo")[0].offsetTop;
      if (this.scrollFlag) {
        if (num1 > e.target.scrollTop > num0) {
          this.tabItemIndex = 0;
        } else if (num2 > e.target.scrollTop && e.target.scrollTop > num1) {
          this.tabItemIndex = 1;
        } else if (e.target.scrollTop > num2) {
          this.tabItemIndex = 2;
        }
      }
    },
    // tab选择
    selectTabItem(item, index) {
      this.scrollFlag = false;
      if (this.isJoin == 1) {
        this.tabItemIndex = index;
        if (index == 0) {
          this.$refs.page.scrollTop =
            document.getElementsByClassName("essentialInfo")[0].offsetTop -
            document.getElementsByClassName("essentialInfo")[0].offsetTop;
        } else if (index == 1) {
          this.$refs.page.scrollTop =
            document.getElementsByClassName("aatestActivities")[0].offsetTop -
            document.getElementsByClassName("essentialInfo")[0].offsetTop;
        } else {
          this.$refs.page.scrollTop =
            document.getElementsByClassName("communityDynamics")[0].offsetTop -
            document.getElementsByClassName("essentialInfo")[0].offsetTop;
        }
      }
      setTimeout(() => {
        this.scrollFlag = true;
      });
    },
    //距离现在时间多久
    dateDiff(hisTime, nowTime) {
      if (!arguments.length) return "";
      var arg = arguments,
        now = arg[1] ? arg[1] : new Date().getTime(),
        diffValue = now - new Date(arg[0].replace(/-/g, "/")).getTime(),
        result = "",
        minute = 1000 * 60,
        hour = minute * 60,
        day = hour * 24,
        halfamonth = day * 15,
        month = day * 30,
        year = month * 12,
        _year = diffValue / year,
        _month = diffValue / month,
        _week = diffValue / (7 * day),
        _day = diffValue / day,
        _hour = diffValue / hour,
        _min = diffValue / minute;
      if (_year >= 1) result = parseInt(_year) + "年前";
      else if (_month >= 1) result = parseInt(_month) + "个月前";
      else if (_week >= 1) result = parseInt(_week) + "周前";
      else if (_day >= 1) result = parseInt(_day) + "天前";
      else if (_hour >= 1) result = parseInt(_hour) + "个小时前";
      else if (_min >= 1) result = parseInt(_min) + "分钟前";
      else result = "刚刚";
      return result;
    },
    // 获取社群详情
    async getDetail() {
      let params = {
        associationId: this.id,
        userId: this.userId,
      };
      let res = await this.$axios.get(`${getAssDetailForMiniUrl}`, {
        params: params,
      });
      if (res.code == 200) {
        this.detail = res.data;
        this.isJoin = res.data.isJoin;
      }
    },
    //弹框确认按钮
    async confirm() {
      let params = {
        id: this.activityId,
        joinFlag: this.dialogFlag,
        userId: this.userId,
      };
      let res = await this.$axios.post(
        `${joinOrQuitUrl}`,
        this.$qs.stringify(params)
      );
      if (res.code === 200) {
        this.getActivityList();
        this.isDialog = false;
      }
    },
    //跳转申请社群页面
    goApplyJoin() {
      this.$router.push({
        name: "applyJoin",
        query: {
          associationId: this.id,
        },
      });
    },
    // 跳转社群动态列表页面
    goCommunitDynamic() {
      this.$router.push({
        name: "communitDynamic",
        query: {
          id: this.id,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding-top: 100px;
  padding-bottom: 40px;
  overflow: scroll;
  position: relative;
  .mainTitle {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    position: relative;
    .mainTitle-left {
      width: 174px;
      font-size: 32px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
      text-align: center;
      position: relative;
      .titleLine {
        width: 100%;
        height: 16px;
        background: #e2e2e2;
        border-radius: 7px;
        position: absolute;
        bottom: 0;
        z-index: -1;
      }
    }
    .mainTitle-right {
      position: absolute;
      display: flex;
      align-items: center;
      right: 0;
      font-size: 28px;
      color: #fe9514;
      img {
        width: 9px;
        height: 18px;
        margin-left: 12px;
      }
    }
  }

  .tabBox {
    width: 100%;
    height: 100px;
    display: flex;
    background-color: #fff;
    position: fixed;
    top: 0;
    z-index: 10000000000;

    .activeTabItem {
      flex: 1;
      height: 100%;
      line-height: 100px;
      text-align: center;
      font-size: 32px;
      font-weight: 600;
      color: #fe9615;
      position: relative;

      .thisTabItemLine {
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        width: 34px;
        height: 6px;
        background: #fe9615;
        border-radius: 3px;
      }
    }

    .tabItem {
      flex: 1;
      height: 100%;
      line-height: 100px;
      text-align: center;
      font-size: 32px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.5);
      position: relative;

      .thisTabItemLine {
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        width: 34px;
        height: 6px;
        background: #fe9615;
        border-radius: 3px;
      }
    }
  }

  .essentialInfo {
    width: 100%;
    padding: 42px 36px 0;
    box-sizing: border-box;
    .bannerBox {
      width: 100%;
      box-sizing: border-box;
      img {
        width: 100%;
        object-fit: contain;
        display: block;
        margin: 0 auto;
      }
    }
    .associationName {
      font-size: 32px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.5);
      padding: 24px 0 78px 0;
      box-sizing: border-box;
    }
    .detailInfo {
      padding: 64px 0 72px;
      box-sizing: border-box;
      .detailitem {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        .label {
          width: 340px;
          font-size: 28px;
          color: rgba(0, 0, 0, 0.85);
        }
        .content {
          flex: 1;
          white-space: nowrap;
          font-size: 28px;
          color: rgba(0, 0, 0, 0.5);
        }
      }
    }
    .memberBox {
      width: 100%;
      height: 74px;
      margin: 64px 0 114px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      position: relative;
      .member {
        width: 74px;
        height: 100%;
        border-radius: 50%;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .ml-16 {
        margin-left: -16px;
      }
      .memberSum {
        display: flex;
        align-items: center;
        font-size: 28px;
        color: #fe9615;
        position: absolute;
        right: 0;
        img {
          margin-left: 6px;
          width: 9px;
          height: 18px;
        }
      }
    }
    .associationContent {
      font-size: 28px;
      color: rgba(0, 0, 0, 0.85);
      padding: 64px 0 130px;
      line-height: 44px;
      box-sizing: border-box;
    }
  }
  .aatestActivities {
    width: 100%;
    padding: 0 36px;
    box-sizing: border-box;
    .activitiesList {
      margin-top: 64px;
      .activitieItem {
        width: 100%;
        height: 440px;
        background: #ffffff;
        box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.1);
        overflow: hidden;
        margin-bottom: 28px;
        position: relative;
        .activitieItem-top {
          width: 100%;
          height: 90px;
          display: flex;
          align-items: center;
          border-bottom: 2px solid rgba(0, 0, 0, 0.05);
          padding: 0 30px;
          box-sizing: border-box;
          img {
            width: 40px;
            margin-right: 14px;
          }
          .activitieName {
            flex: 1;
            white-space: nowrap;
            font-size: 28px;
            color: rgba(0, 0, 0, 0.85);
          }
          .date {
            font-size: 24px;
            color: rgba(0, 0, 0, 0.5);
          }
        }
        .activitieItem-bottom {
          width: 100%;
          height: calc(100% - 90px);
          box-sizing: border-box;
          padding: 20px 30px 0;
          margin-bottom: 34px;
          .info-top {
            width: 100%;
            height: 148px;
            box-sizing: border-box;
            display: flex;
            img {
              width: 246px;
              height: 100%;
              margin-right: 20px;
              border-radius: 12px;
            }
            .activitie-info {
              font-size: 24px;
              color: rgba(0, 0, 0, 0.85);
              line-height: 36px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 4;
              -webkit-box-orient: vertical;
            }
          }
          .info-bottom {
            width: 100%;
            font-size: 24px;
            color: rgba(0, 0, 0, 0.85);
            margin-top: 34px;
            p {
              margin-bottom: 4px;
            }
          }
        }
        .btn_box {
          width: 194px;
          height: 62px;
          text-align: center;
          line-height: 62px;
          font-size: 26px;
          box-shadow: 0px 4px 8px 0px rgba(225, 225, 225, 0.5);
          border-radius: 16px 0px 16px 0px;
          position: absolute;
          bottom: 0;
          right: 0;
        }
        .orange {
          color: #fff;
          background: linear-gradient(180deg, #ffc055 0%, #fe9615 100%);
        }

        .withe {
          border: 2px solid;
          color: #fe9919;
        }

        .empty {
          background: linear-gradient(180deg, #ededed 0%, #d8d8d8 100%);
          color: #a8a8a8;
        }
      }
    }
  }
  .communityDynamics {
    width: 100%;
    padding: 0 36px;
    box-sizing: border-box;
    .dynamicsItem {
      .dynamicsItem-top {
        width: 100%;
        height: 74px;
        display: flex;
        align-items: center;
        margin: 32px 0;
        font-size: 28px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
        img {
          border-radius: 50%;
          width: 74px;
          height: 74px;
          margin-right: 16px;
        }
      }
      .dynamicsItem-center {
        font-size: 32px;
        color: rgba(0, 0, 0, 0.85);
        line-height: 44px;
        img {
          margin-top: 34px;
          width: 100%;
          height: auto;
          border-radius: 16px;
        }
      }
      .dynamicsItem-bottom {
        display: flex;
        align-items: center;
        font-size: 24px;
        color: rgba(0, 0, 0, 0.5);
        .time {
          flex: 1;
        }
        .num {
          margin-left: 14px;
        }
        img {
          margin-left: 38px;
        }
        .img1 {
          width: 24px;
          height: 22px;
        }
        .img2 {
          width: 32px;
          height: 34px;
        }
      }
    }
  }
  .blackBg {
    padding: 0 90px;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 1000000000000000;
    background-color: rgba(0, 0, 0, 0.25);

    .diglog {
      width: 568px;
      background: #ffffff;
      z-index: 105;
      border-radius: 16px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .top_box {
        width: 100%;
        height: 186px;
        line-height: 186px;
        text-align: center;
        font-size: 32px;
        font-weight: 600;
        color: #333333;
      }

      .bottom_box {
        width: 100%;
        height: 86px;
        line-height: 86px;
        border-top: 2px solid rgba(0, 0, 0, 0.1);
        display: flex;
        font-size: 36px;

        .left_box {
          flex: 1;
          text-align: center;
          color: #333333;
          border-right: 2px solid rgba(0, 0, 0, 0.1);
        }

        .right_box {
          flex: 1;
          text-align: center;
          font-weight: 600;
          color: #fe9615;
        }
      }
    }
  }
  .underReview {
    text-align: center;
    line-height: 66px;
    margin: 0 auto;
    width: 550px;
    height: 66px;
    border-radius: 10px;
    border: 2px solid;
    font-size: 32px;
    font-weight: 600;
    color: #fe9717;
  }
  .joinCommunity {
    text-align: center;
    line-height: 66px;
    margin: 0 auto;
    width: 550px;
    height: 66px;
    border-radius: 10px;
    font-size: 32px;
    font-weight: 600;
    color: #fff;
    background: linear-gradient(180deg, #ffc055 0%, #fe9514 100%);
  }
  .auditFailed {
    text-align: center;
    line-height: 66px;
    margin: 0 auto;
    width: 550px;
    height: 66px;
    border-radius: 10px;
    font-size: 32px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.25);
    background: linear-gradient(180deg, #ededed 0%, #d8d8d8 100%);
  }
}
</style>
